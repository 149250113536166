import React, { useEffect, useState, useRef } from "react"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Pagination from "../../components/pagination/pagination"
import { useStaticQuery, graphql } from "gatsby"
import tracking from "../../utils/tracking"
import ImgCustom from "../../components/imgCustom/imgCustom"
import BlockConseilsList from "../../components/blockConseilsList/blockConseilsList"
import { element } from "prop-types"
import links from "../../utils/links"
import "./conseilsAll.scss"

const temp = elem => {
  const [visible, setVisible] = useState(true)

  const delay = 1
  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, delay)
  }, [delay])

  return visible ? <div>{elem}</div> : <div />
}

const blockConseilsList = ({ data, pageContext }) => {
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])
  const dataFormated = () => {
    let result = []
    if (data) {
      if (data.hasOwnProperty("allNodePostConseils")) {
        const { edges } = data.allNodePostConseils
        if (edges) {
          edges.forEach(element => {
            const { node } = element
            if (
              node &&
              node?.relationships.taxonomy_term__post_categorie !== null
            ) {
              const id = node.relationships.taxonomy_term__post_categorie[0].id
              const name_taxo =
                node.relationships.taxonomy_term__post_categorie[0].name
              const slug_taxo =
                node.relationships.taxonomy_term__post_categorie[0].field_slug
                  .value
              const description = node.field_description_article_short.value
              // const title = node.field_titre_article_short
              const title = node.title
              const src = node.relationships.field_cover_short.localFile
              const alt = node.field_cover_short.alt
              const slug = node.field_slug

              if (id && name_taxo) {
                const indexByIdTaxo = result.findIndex(el => el.id === id)
                const createRow = {
                  category: name_taxo,
                  slug_category: slug_taxo,
                  title: title,
                  description: description,
                  src: src,
                  alt: alt,
                  slug: slug,
                }
                if (indexByIdTaxo === -1) {
                  result.push({
                    id: id,
                    category: name_taxo,
                    slug_category: slug_taxo,
                    data: [createRow],
                  })
                }
                if (indexByIdTaxo !== -1) {
                  result[indexByIdTaxo].data.push(createRow)
                }
              }
            }
          })
        }
        // if (result.length) {
        //     result.forEach(elm => {
        //         elm.data.sort((a, b) => (a.order > b.order ? 1 : -1))
        //     })
        // }
      }
    }
    return result
  }
  const dataList = dataFormated()
  const categoryList = [...data.allTaxonomyTermPostCategorie.nodes].sort(
    (a, b) => (a.weight > b.weight ? 1 : -1)
  )

  const formatListDesktop = () => {
    return (
      categoryList.map((el, idx) => (
        <a
          key={idx}
          href={el.field_slug.value === "alarme-maison"
          ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}`) + "/" + campagne
          : (`/conseils/${el.field_slug.value}`) + "/" + campagne}
          className={"ls-n mb-5 item is-active m-2"}
        >
          <button className={"btn-hover-underline ta-l"}>
            {el.name}
          </button>
        </a>
      ))
    ) 
  }

  const formatListResponsive = () => {
    return (
      categoryList.map((el, index) => (
        <li
            key={index}
            onClick={() =>
              el.field_slug.value === "alarme-maison"
                ? (window.location.href = `/conseils/${el.field_slug.value}/${el.field_slug.value}` + "/" + campagne)
                : (window.location.href = `/conseils/${el.field_slug.value}` + "/" + campagne)
            }
            className={
              selected === index
                ? "ls-n mb-5 item is-active"
                : "ls-n mb-5 item"
            }
        >
          <a
            key={index}
            href={el.field_slug.value === "alarme-maison"
            ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}` + "/" + campagne)
            : (`/conseils/${el.field_slug.value}` + "/" + campagne)
            }
          >
            <button className="btn-hover-underline ta-l ">
              {el.name}
            </button>
          </a>
        </li>
      ))
    )
  }

  const seoPage = {
    title: data.nodePageConseils.field_seo_titre,
    description: data.nodePageConseils.field_seo_description,
  }

  if (pageContext.currentPage > 1) {
    seoPage.title = seoPage.title + " | Page " + pageContext.currentPage
  }

  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "conseil")
    tracking.track("page_name", seoPage.title + (isTherecampagne ? "/" + campagne : ""))
    tracking.track("categories", "telesurveillance")
    tracking.trackInit()
  }, [])

  const topPost = data.nodePageConseils.relationships.field_top_post

  let pagination = {
    currentPage: pageContext.currentPage,
    next: 0,
    prev: 0,
    tabs: [pageContext.numPages],
  }

  const breadcrumb = [
    {
      name: "Accueil",
      url: links.getLink("conseils") + campagne,
    },
  ]

  const isNext = () => {
    if (pageContext.currentPage < pageContext.numPages) {
      pagination = {
        ...pagination,
        next: pageContext.currentPage + 1,
      }
    } else {
      pagination = {
        ...pagination,
        next: 0,
      }
    }
  }

  const isPrev = () => {
    if (pageContext.currentPage > 0) {
      pagination = {
        ...pagination,
        prev: pageContext.currentPage - 1,
      }
    } else {
      pagination = {
        ...pagination,
        prev: 0,
      }
    }
  }

  const fillTabs = () => {
    for (let i = 0; i < pageContext.numPages; i++) {
      pagination.tabs[i] = i + 1
    }
  }

  isNext()
  isPrev()
  fillTabs()

  function formatUrl (page, slug) {
    if (page) {
      if (slug == "") {
        if (page === 1) {
          return links.getLink("conseils") + campagne
        } else {
          return `/conseils-page-${page}` + (isTherecampagne ? "/" + campagne : "")
        }
      }
      else {
        if (page === 1) {
          return `/conseils/${slug}` + (isTherecampagne ? "/" + campagne : "")
        } else {
          return `/conseils/${slug}-page-${page}` + (isTherecampagne ? "/" + campagne : "")
        }
      }
    }
  }

  const formatPrePagination = (data, slug) => {
    if (data.prev != 0){
      return (
          <li className="li">
              <a rel="prev" href = {formatUrl(data.prev,slug)} >
              <button
                className="d-f mr-1  px-0 ai-c jc-c icon-arrow-pagination-next"
                style={{
                  height: "15px",
                  width: "25px",
                  transform: "rotate(180deg)",
                }}
              ></button></a>
          </li>
      )
    }
    else {
      return ''
    }
  }

  const formatPostPagination = (data, slug) => {
    if (data.next != 0){
      return (
          <li className="li">
            <a rel="next" href = {formatUrl(data.next,slug)} >
            <button
              className="d-f px-0  ml-1 ai-c jc-c icon-arrow-pagination-next"
              style={{ height: "15px", width: "25px" }}
            ></button></a>
          </li>
      )
    }
    else {
      return ''
    }
  }

  const formatMidPagination = (data, slug) => {
      return (
        data.tabs.map((el, index) => (
          <li className="ls-n 2" key={index}>
            <a href = {formatUrl(el,slug)} >
            <button
              className={
                data.currentPage === el
                  ? "page-item  ff-gb is-active"
                  : "page-item ff-gb "
              }
            >
              <span>{el}</span>
            </button></a>
          </li>
        ))
      )
  }

  let i = 0

  const useWindoSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    })
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
      window.addEventListener("resize", handleResize)
      handleResize()
      return () => window.removeEventListener("resize", handleResize)
    }, [])
    return windowSize
  }
  const [openNav, setOpenNav] = useState(false)
  const [selected, setSelected] = useState(null)
  const size = useWindoSize()

  const selectCategory = index => {
    setSelected(index)
  }
  
    return (
      <Layout isViewPromoOnHeader={true}>
        <Seo
          title={seoPage.title}
          description={seoPage.description + (pageContext.currentPage > 1 ? " Page " + pageContext.currentPage + "." : "")}
          linkCanonical={
            pageContext.currentPage > 1
              ? `conseils-page-${pageContext.currentPage}`
              : "conseils/"
          }
          nbPagesConseils={pageContext.numPages}
          page={pagination}
        />
        <div className="block-page-conseil">
          <div className="bc-2 d-f jc-c ai-c py-6 px-md-3 px-md-3">
            {/* <div className="breadcrumb p-1">
                          Accueil
                      </div> */}
            <div
              className="title-on-hero"
              dangerouslySetInnerHTML={{
                __html: data.nodePageConseils.field_banner_titre.value,
              }}
            ></div>
          </div>
          <div className="wrapper container-page-conseil d-f ai-c jc-c fd-c fd-md-c">
            {/** TOP ARTICLE */}
            <BlockConseilsList data={topPost} top={true} />
  
            {/** CATEGORIES */}
            {size.width > 768 ? (
              <>
                <div className="separator icon mr-2 ml-2 "></div>
                <div className="d-f ai-c jc-c fd-r p-0">
                  <span className={"ls-n mb-5 item is-active m-2"}>
                    <button className="btn-hover-underline ta-l c-1">
                      Tous les articles
                    </button>
                  </span>
                  {categoryList.map((el, idx) => (
                    <a
                      key={idx}
                      href={el.field_slug.value === "alarme-maison"
                      ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}`) + (isTherecampagne ? "/" + campagne : "")
                      : (`/conseils/${el.field_slug.value}`) + (isTherecampagne ? "/" + campagne : "")}
                      className={"ls-n mb-5 item is-active m-2"}
                    >
                      <button className={"btn-hover-underline ta-l"}>
                        {el.name}
                      </button>
                    </a>
                  ))}
                </div>
              </>
            ) : (
              <div className="category-list">
                <label className="label-current-select br-12 d-n d-md-f p-r bcg-2">
                  <input
                    type="text"
                    readOnly
                    className="input-hide-toggle"
                    onClick={() => setOpenNav(openNav ? false : true)}
                    onBlur={() =>
                      setTimeout(() => {
                        setOpenNav(false)
                      }, 200)
                    }
                  />
                  <p className="d-f ai-c jc-sb mb-0 w-100 ff-gbook">
                    {selected === null
                      ? "Toutes les articles"
                      : dataList[selected].name}
                    <span className="icon-arrow-down-c1 d-b"></span>
                  </p>
                </label>
                <ul
                  className={
                    openNav
                      ? "ul d-f fd-c is-active nav-category"
                      : "nav-category ul d-f fd-c"
                  }
                >
                  <li
                    onClick={() => selectCategory(null)}
                    className={
                      selected === null
                        ? "ls-n item mb-5 is-active"
                        : "ls-n item mb-5"
                    }
                  >
                    <button className="btn-hover-underline ta-l">
                      Toutes les articles
                    </button>
                  </li>
                  {categoryList.map((el, index) => (
                    <li
                        key={index}
                        onClick={() =>
                          el.field_slug.value === "alarme-maison"
                            ? (window.location.href = `/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                            : (window.location.href = `/conseils/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                        }
                        className={
                          selected === index
                            ? "ls-n mb-5 item is-active"
                            : "ls-n mb-5 item"
                        }
                    >
                      <a
                        key={index}
                        href={el.field_slug.value === "alarme-maison"
                        ? (`/conseils/${el.field_slug.value}/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                        : (`/conseils/${el.field_slug.value}` + (isTherecampagne ? "/" + campagne : ""))
                        }
                      >
                        <button className="btn-hover-underline ta-l ">
                          {el.name}
                        </button>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
  
            {/** LIST ARTCILES */}
            <div className="d-f ai-fs jc-c fd-r f-w">
              {dataList.map(el =>
                i !== dataList.length - 1 ? (
                  <>
                    <BlockConseilsList data={el.data} top={false} />
                    {/* <p style={{ display: "none" }}>{i++}</p> */}
                    <>{temp(i++)}</>
                  </>
                ) : (
                  <BlockConseilsList data={el.data} top={false} limit={true} />
                )
              )}
            </div>
  
            {/** PAGINATION */}
            <div className="py-3">
              {pagination && (
                <Pagination
                  data={pagination}
                  addClass={"jc-fe"}
                  conseil={true}
                  prePagination={formatPrePagination(pagination,"")}
                  midPagination={formatMidPagination(pagination,"")}
                  postPagination={formatPostPagination(pagination,"")}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
    
}

export const query = graphql`
  query ($skip: Int, $limit: Int) {
    nodePageConseils {
      title
      field_seo_titre
      field_seo_description
      field_banner_titre {
        value
      }
      relationships {
        field_top_post {
          title
          field_titre_article_short
          field_slug
          relationships {
            field_cover_short {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
            taxonomy_term__post_categorie {
              name
              field_slug {
                value
              }
            }
          }
          field_cover_short {
            alt
          }
          field_description_article_short {
            value
          }
        }
      }
    }
    allNodePostConseils(
      skip: $skip
      limit: $limit
      sort: { fields: changed, order: DESC }
    ) {
      edges {
        node {
          title
          field_slug
          field_titre_article_short
          relationships {
            taxonomy_term__post_categorie {
              name
              id
              field_slug {
                value
              }
            }
            field_cover_short {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, formats: NO_CHANGE)
                }
              }
            }
          }
          field_description_article_short {
            value
          }
          field_cover_short {
            alt
          }
        }
      }
    }
    allTaxonomyTermPostCategorie {
      nodes {
        name
        field_slug {
          value
        }
        weight
      }
    }
  }
`

export default blockConseilsList