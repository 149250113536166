import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import ImgCustom from "../../components/imgCustom/imgCustom"
import "./blockConseilsList.scss"
import links from "../../utils/links"

const temp = elem => {
  const [visible, setVisible] = useState(true)

  const delay = 1
  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, delay)
  }, [delay])

  return visible ? <div>{elem}</div> : <div />
}

const blockConseilsList = ({
  data,
  top = false,
  threeLast = false,
  limit = false,
}) => {

  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])

  let i = 0
  const elRef = useRef()
  let line
    if (top) {
      return (
        <div className="top-article d-f fd-r ai-fs p-0 jc-c">
          <div className="top-article-media">
            <ImgCustom
              src={data.relationships.field_cover_short.localFile}
              // alt={data.field_cover_short.alt}
              alt={data.title}
            />
          </div>
          <div className="top-article-text d-f fd-c">
            <div className="top-article-tc d-f fd-c ai-fs p-0">
              <span className="top-article-category c-1">
                {data.relationships.taxonomy_term__post_categorie[0].name}
              </span>
              <h2 className="top-article-title">{data.title}</h2>
            </div>
            {/* {data.field_titre_article_short} */}
            <div
              className="top-article-description"
              dangerouslySetInnerHTML={{
                __html: data.field_description_article_short.value,
              }}
            ></div>
            <span className={"ls-n mb-5 item c-1"}>
            <a
                href={
                  data.field_slug.includes("/")
                    ? `/conseils/${data.relationships.taxonomy_term__post_categorie[0].field_slug.value}${data.field_slug}` + (isTherecampagne ? "/" + campagne : "")
                    : `/conseils/${data.relationships.taxonomy_term__post_categorie[0].field_slug.value}/${data.field_slug}` + (isTherecampagne ? "/" + campagne : "")
                }
              >
              <button className="btn-hover-underline ta-l c-1">
                Lire la suite
              </button>
              </a>
            </span>
          </div>
        </div>
      )
    } else {
      return (
        <>
          {data.map((el, idx) => (
            <>
              {threeLast ? (
                <>
                  {idx < 3 && (
                    <div className="card-article-suggestion">
                      <div
                        className="card-media-suggestion"
                        onClick={() =>
                          (window.location.href = `/conseils/${el.slug_category}/${el.slug}` + (isTherecampagne ? "/" + campagne : ""))
                        }
                      >
                        <ImgCustom
                          src={el.src}
                          // alt={el.alt}
                          alt={el.title}
                        />
                      </div>
                      <div className="card-info-suggestion">
                        <div className="card-text-suggestion ">
                          <div className="card-tc-suggestion d-f fd-c ai-fs p-0">
                            <span className="card-category-suggestion c-1">
                              {el.category}
                            </span>
                            <h3
                              className="card-title-suggestion"
                              ref={el => {
                                line = el
                              }}
                            >
                              {el.title}
                            </h3>
                          </div>
                          <div
                            ref={el => {
                              // 3 lines
                              if (line?.clientHeight > 50) {
                                el?.classList.remove("l-c4-1")
                                el?.classList.remove("l-c4-2")
                                el?.classList.add("l-c3")
                              }
                              // 2 lines
                              else if (
                                line?.clientHeight > 40 &&
                                line?.clientHeight < 50
                              ) {
                                el?.classList.remove("l-c3")
                                el?.classList.remove("l-c4-1")
                                el?.classList.add("l-c4-2")
                              }
                              // 1 line
                              else {
                                el?.classList.remove("l-c3")
                                el?.classList.remove("l-c4-2")
                                el?.classList.add("l-c4-1")
                              }
                            }}
                            className="card-description-suggestion fw-400 fs-14 lh-21 d-f cg-3 d-f fd-c"
                            dangerouslySetInnerHTML={{
                              __html: el.description,
                            }}
                          ></div>
                        </div>
                        <span className={"ls-n mb-1 item c-1"}>
                          <button
                            onClick={() =>
                              (window.location.href = `/conseils/${el.slug_category}/${el.slug}` + (isTherecampagne ? "/" + campagne : ""))
                            }
                            className="btn-hover-underline ta-l c-1"
                          >
                            Lire la suite
                          </button>
                        </span>
  
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="card-article">
                    <div
                      className="card-media"
                      onClick={() =>
                        (window.location.href = `/conseils/${el.slug_category}/${el.slug}` + (isTherecampagne ? "/" + campagne : ""))
                      }
                    >
                      <ImgCustom
                        src={el.src}
                        // alt={el.alt}
                        alt={el.title}
                      />
                    </div>
                    <div className="card-info">
                      <div className="card-text">
                        <div className="card-tc d-f fd-c ai-fs p-0">
                          <span className="card-category c-1">{el.category}</span>
                          <h2
                            className="card-title"
                            ref={el => {
                              line = el
                            }}
                          >
                            <a
                          href={
                            el.slug.includes("/") ? 
                            `/conseils/${el.slug_category}${el.slug}` + (isTherecampagne ? "/" + campagne : "")
                            : `/conseils/${el.slug_category}/${el.slug}` + (isTherecampagne ? "/" + campagne : "")
                          }
                          >
                            {el.title}
                            </a>
                          </h2>
                        </div>
                        <div
                          ref={el => {
                            // 3 lines
                            if (line?.clientHeight > 50) {
                              el?.classList.remove("l-c4-1")
                              el?.classList.remove("l-c4-2")
                              el?.classList.add("l-c3")
                            }
                            // 2 lines
                            else if (
                              line?.clientHeight > 40 &&
                              line?.clientHeight < 50
                            ) {
                              el?.classList.remove("l-c3")
                              el?.classList.remove("l-c4-1")
                              el?.classList.add("l-c4-2")
                            }
                            // 1 line
                            else {
                              el?.classList.remove("l-c3")
                              el?.classList.remove("l-c4-2")
                              el?.classList.add("l-c4-1")
                            }
                          }}
                          className="card-description fw-400 fs-14 lh-21 d-f cg-3 d-f fd-c"
                          dangerouslySetInnerHTML={{
                            __html: el.description,
                          }}
                        ></div>
                      </div>
                      <span className={"ls-n mb-5 item c-1"}>
                        <a
                          href={
                            el.slug.includes("/")
                              ? `/conseils/${el.slug_category}${el.slug}` + (isTherecampagne ? "/" + campagne : "")
                              : `/conseils/${el.slug_category}/${el.slug}` + (isTherecampagne ? "/" + campagne : "")
                          }
                        >
                          <button
                            // onClick={() => window.location.href = `/conseils/${el.slug_category}/${el.slug}`}
                            className="btn-hover-underline ta-l c-1"
                          >
                            Lire la suite
                          </button>
                        </a>
                      </span>
                    </div>
                  </div>
                  {!limit ? (
                    <div className="line"></div>
                  ) : (
                    i !== data.length - 1 && <div className="line"></div>
                  )}
                  <>{temp(i++)}</>
                </>
              )}
            </>
          ))}
        </>
      )
    }
}

export default blockConseilsList
